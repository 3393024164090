import { NgModule } from '@angular/core';
import { DecimalNumberDirective } from '../../directives/decimal-number.directive';
import { InViewportDirective } from '../../directives/in-viewport.directive';



@NgModule({
  imports: [InViewportDirective],
  declarations: [DecimalNumberDirective],
  exports: [DecimalNumberDirective, InViewportDirective]
})
export class DirectivesModule { }
